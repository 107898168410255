@import "../../node_modules/overlayscrollbars/styles/overlayscrollbars.css";
@import "./scrollbars.css";

.modal_get-in-touch [data-overlayscrollbars] {
    position: static !important;
}
.modal_get-in-touch .js-scrollbar {
    position: static !important;
}
.modal_get-in-touch [data-overlayscrollbars-viewport] {
    position: static !important;
}
html {
    height: auto;
}
body {
    background: none transparent !important;
}
body.no-scroll {
    overflow: hidden !important;
}

.nav_scroll {
    transform: translateY(-150px);
    transition: 1s ease;
}
.nav_scroll.visible {
    transform: translateY(0);
}
.nav_scroll .nav_menu_link {
    color: #000;
}
.nav_scroll .button.is-secondary {
    border: 1px solid #000 !important;
}
.nav_scroll .button.is-secondary .text-block {
    color: #000 !important;
}
.nav_fixed {
    transition: 1s ease;
}
.nav_fixed .button.is-secondary:hover {
    border-color: #fff;
}

.nav_right.dark-child-button .button.is-secondary:hover:hover {
    border-color: #000;
}
.nav_fixed.hidden {
    transform: translateY(-150px);
}
.menu-icon {
    position: relative;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.menu-icon__checkbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
}
.menu-icon div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.625rem;
}
.menu-icon span {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: black;
    border-radius: 0px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
    top: 0;
}
.menu-icon span:last-of-type {
    bottom: 0;
}

.menu-icon__checkbox:checked + div span:first-of-type {
    transform: rotate(45deg);
    top: 2px;
}
.menu-icon__checkbox:checked + div span:last-of-type {
    transform: rotate(-45deg);
    top: 2px;
}
.menu-icon:hover .menu-icon__checkbox:checked + div span:first-of-type,
.menu-icon:hover .menu-icon__checkbox:checked + div span:last-of-type {
    width: 100%;
}

.menu-icon:hover span:first-of-type {
    width: 26px;
}
.menu-icon:hover span:last-of-type {
    width: 12px;
}
.products-menu-toggle svg {
    transition: 0.3s ease;
}
.products-menu-toggle.open svg {
    transform: rotate(180deg);
}

.modal_get-in-touch {
    height: 100dvh !important;
}
.videos-info-text-code-wrapper span,
.videos-info-text {
    -webkit-touch-callout: none !important;
    border: none !important;
}

.swiper-wrapper {
    transition-timing-function: linear;
}
.overview-navigation,
.swiper-overview-prev,
.swiper-overview-next {
    cursor: none !important;
}
.swiper-slide .testimonials-slide {
    height: fit-content !important;
    opacity: 0 !important;
}
.swiper-slide.swiper-slide-active .testimonials-slide {
    opacity: 1 !important;
}
.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 0;
    border: 1px solid black !important;
    margin: 0 4px !important;
    background: transparent !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: black !important;
}
.testimonials-swiper-nav .swiper-button-next,
.testimonials-swiper-nav .swiper-button-prev {
    position: static;
}
.swiper-button-prev::after,
.swiper-button-next::after {
    display: none !important;
}
.product-config-swiper-progress,
.overview-swiper-progress {
    background-color: #cfc5af !important;
    height: 1px !important;
    top: 100% !important;
}
.product-config-swiper-progress .swiper-pagination-progressbar-fill,
.overview-swiper-progress .swiper-pagination-progressbar-fill {
    height: 2px !important;
    background-color: #000 !important;
    margin-bottom: 0.5px !important;
}
.swiper-scrollbar-drag {
    height: 3px;
    top: -1px;
    background: black;
    border-radius: 0;
}
.js-follow-arrow {
    position: absolute !important;
    opacity: 0;
    z-index: 10; /* Keep buttons on top */
    pointer-events: none; /* Prevent buttons from interfering with cursor movement */
}

@media (max-width: 992px) {
    .js-testimonials-swiper {
        padding-bottom: 6.25vw !important;
    }
    .js-testimonials-swiper .swiper-pagination {
        bottom: 0 !important;
        top: unset !important;
    }
}
.dark-child-button .button {
    border-color: black;
    color: black;
}
.w-nav-brand {
    padding-left: 0 !important;
}
.custom-select-list .w-dropdown-link.w--current {
    color: black;
}
.custom-select-toggle.w-dropdown-toggle.w--open {
    border-bottom-color: #000;
}
.custom-select-icon {
    transition: 0.3s ease;
}
.custom-select-toggle.w-dropdown-toggle.w--open .custom-select-icon {
    transform: rotate(180deg);
}

/** Border Transitions */
.js-animated-border {
    transition: width 1s ease-in-out;
}
/** Hovers of Images */
@media (min-width: 992px) {
    .mega-menu-link-image,
    .product-list-image,
    .product-image {
        transition: 0.3s ease;
    }

    .mega-menu-link:hover .mega-menu-link-image,
    .product-list-item:hover .product-list-image,
    .scroll-item-product:hover .product-image {
        transform: scale(1.1);
    }

    .mega-menu-column.last-child.image-bg {
        background-size: 100%;
        transition: background-size 0.5s ease;
    }
    .mega-menu-column.last-child.image-bg:hover {
        background-size: 110%;
    }

    .mega-menu-column.last-child.image-bg:hover .button {
        border-color: #b8fcbf;
    }
}
.products-menu-toggle.open .nav-menu-link {
    border-color: #fff;
}
.products-menu-toggle.open .nav-menu-link.dark {
    border-color: #000;
}
input,
textarea {
    border-radius: 0;
    -webkit-appearance: none;
}
input[type="submit"]:disabled + .icon-arrow {
    display: none;
}
#Contact-Form-Topic + .custom-select-dropdown-link {
    display: none !important;
}
.hero-foot-cta:hover .button {
    background-color: #c6ffcd;
}
/* Forms */
.form_checkbox-icon {
    transition: border-color 0.25s ease;
}
.form_checkbox:hover .form_checkbox-icon:not(.w--redirected-checked) {
    border-color: black;
}
.newsletter-form-wrapper
    + .form_checkbox:hover
    .form_checkbox-icon:not(.w--redirected-checked) {
    border-color: #97f0a1;
}
.custom-select-dropdown-link.w--current {
    position: relative;
    background-color: var(--base-color-brand--sand-200);
    background-image: linear-gradient(
        to bottom,
        var(--base-color-brand--sand-200),
        var(--base-color-brand--sand-200)
    );
}
.custom-select-dropdown-link.w--current::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path d="M18 7L9.69231 17L6 13" stroke="black" stroke-width="1.5" stroke-linecap="square"/></svg>');
}

/* Cases & News */

@media (pointer: fine) {
    .blog-list-item_image {
        transform-origin: bottom left;
    }
    .blog-list-item_read-more {
        transform: translateY(100%);
    }
    .blog-list-item_link:hover .blog-list-item_image {
        transform: scale(1.1);
    }

    .blog-list-item_link:hover .blog-list-item_read-more {
        transform: translateY(0);
    }
    .position_list-item-arrow {
        transform: translateX(-100%);
    }
    .positions_list-item-link:hover .position_list-item-arrow {
        transform: translateX(0);
    }
    .scroll-item-product:hover .product-link-icon {
        transform: translateX(100%);
    }
}
.blog-list_filter-button:hover span,
.blog-list_filter-button.active span {
    color: black;
}
.blog-list_filters
    + .blog-list_wrapper
    .blog-list_item:first-child
    .blog-list-item_border-top {
    display: none !important;
}
@media (min-width: 768px) {
    .blog-list_filters
        + .blog-list_wrapper
        .blog-list_item:nth-child(2)
        .blog-list-item_border-top {
        display: none !important;
    }
}

/* Hovers */

.contact-menu-item,
.footer-copy_link {
    position: relative;
}

.contact-menu-item::before,
.footer-copy_link::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    transition: 0.25s ease;
    left: 0%;
    background-color: var(--base-color-brand--sand-200);
}
.contact-menu-item:hover::before,
.footer-copy_link:hover::before {
    width: 100%;
    width: 0;
    left: unset;
    right: 0;
}
.footer-menu-item,
.nav-menu-link,
.products-menu-toggle {
    position: relative;
}
.footer-menu-item::before,
.nav-menu-link::before,
.products-menu-toggle::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 0;
    bottom: 0;
    transition: 0.25s ease;
    right: 0%;
    background-color: var(--base-color-brand--sand-200);
}
.footer-menu-item:hover::before,
.nav-menu-link:hover::before,
.nav-menu-link.w--current::before,
.products-menu-toggle:hover::before,
.products-menu-toggle.open::before {
    width: 100%;
    right: unset;
    left: 0;
}
.products-menu-toggle.dark::before,
.nav-menu-link.dark::before {
    background-color: var(--base-color-neutral--black);
}
.products-menu-toggle .nav-menu-link::before {
    display: none;
}

/* Rich Text Editor */
.w-richtext-align-fullwidth {
    max-width: 100% !important;
}
.w-richtext-align-fullwidth div,
.w-richtext-align-fullwidth img {
    width: 100%;
}
/* CookieYes Styles */
.cky-consent-bar {
    background-color: #27443f !important;
    border: none !important;
}
.cky-consent-bar * {
    color: #fffbf2 !important;
}
.cky-consent-bar p {
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    font-weight: 500 !important;
}
.cky-title {
    display: none !important;
}
.cky-notice-btn-wrapper {
    flex-direction: row !important;
}
@media (max-width: 768px) {
    .cky-notice-btn-wrapper {
        margin-top: 2rem !important;
    }
    .cky-prefrence-btn-wrapper {
        display: flex !important;
        flex-direction: row-reverse !important;
        flex-wrap: nowrap !important;
    }
    .cky-prefrence-btn-wrapper .cky-btn {
        margin-top: 0 !important;
    }
}
@media (max-width: 576px) {
    .cky-notice-btn-wrapper {
        flex-direction: row-reverse !important;
    }

    .cky-notice-btn-wrapper .cky-btn-customize {
        margin-right: 0.5rem;
    }
    .cky-notice-btn-wrapper .cky-btn-accept {
        margin-left: 0.5rem;
    }
}
@media (min-width: 768px) {
    .cky-notice-btn-wrapper {
        justify-content: flex-start !important;
    }
    .cky-prefrence-btn-wrapper .cky-btn,
    .cky-notice-btn-wrapper .cky-btn {
        width: fit-content !important;
        flex: unset !important;
    }
    .cky-prefrence-btn-wrapper {
        justify-content: flex-end !important;
        flex-direction: row !important;
    }
}
.cky-btn {
    color: #fffbf2 !important;
    border-radius: 0 !important;
    border: 1px solid #fffbf2 !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-transform: uppercase !important;
    height: 2.75rem !important;
    margin-top: 0 !important;
}
.cky-btn.cky-btn-accept {
    background-color: #97f0a1 !important;
    border-color: #97f0a1 !important;
    color: #000 !important;
}

@media (min-width: 768px) {
    .cky-consent-bar {
        padding: 2rem 1.667vw !important;
    }
}
@media (min-width: 992px) {
    .cky-notice-des {
        padding-right: 12rem;
    }
}
.cky-preference-center {
    background-color: #fffbf2 !important;
    border-radius: 0 !important;
    border: none !important;
}
.cky-preference-center * {
    color: #000 !important;
}
.cky-preference-center p {
    font-size: 1rem !important;
    line-height: 1.625rem !important;
    font-weight: 400 !important;
}
.cky-btn.cky-btn-preferences {
    color: #000 !important;
    border-color: #000 !important;
}

.cky-preference-header,
.cky-preference-body-wrapper,
.cky-accordion-wrapper,
.cky-accordion,
.cky-accordion-wrapper,
.cky-footer-wrapper,
.cky-prefrence-btn-wrapper {
    border: none !important;
}
.cky-preference-header {
    padding-top: 2.5rem !important;
}
@media (max-width: 578px) {
    .cky-preference-header {
        padding-top: 4.5rem !important;
    }
}
.cky-footer-shadow {
    display: none !important;
}

[data-cky-tag="detail-powered-by"] {
    display: none !important;
}

.cky-btn-close img {
    opacity: 0 !important;
}
.cky-btn-close {
    width: 2rem !important;
    height: 2rem !important;
    position: absolute !important;
    top: 2rem;
    right: 1rem;
}
.cky-btn-close::after {
    content: url("https://cdn.prod.website-files.com/66b35e3b31c5b98890bb6a76/66e062b782b25d135932d01f_close-32px.svg ") !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
}
@media (min-width: 768px) {
    .cky-btn-close {
        top: 1.5rem !important;
        right: 1.5rem !important;
    }
}
.cky-preference-content-wrapper {
    padding: 0 0 2rem 0 !important;
}
.cky-accordion-wrapper {
    padding: 1.25rem 0 !important;
}
.cky-accordion-item {
    margin-top: 0.75rem !important;
}
.cky-preference-title {
    font-size: 2rem !important;
    line-height: 2.25rem !important;
}
@media (min-width: 768px) {
    .cky-preference-title {
        font-size: 2.5rem !important;
        line-height: 3rem !important;
    }
}

.cky-accordion-header-wrapper {
    padding-left: 2.5rem !important;
    position: relative !important;
}
.cky-always-active,
.cky-switch input[type="checkbox"] {
    position: absolute !important;
    top: 0.25rem !important;
    color: transparent !important;
    font-size: 0 !important;
    left: 0 !important;
    z-index: 9;
    width: 1.25rem !important;
    background: transparent !important;
    height: 1.25rem !important;
    border-radius: 0 !important;
    border: 1px solid #000000 !important;

    background: no-repeat 60% 50% #fffbf2 !important;
    background-size: 9px auto !important;
}
.cky-always-active {
    position: relative;
}

.cky-switch input[type="checkbox"]:after {
    content: "";
    width: 500px;
    max-width: 80vw;
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    height: 36px;
}

.cky-switch input[type="checkbox"]:before {
    display: none !important;
}

.cky-always-active {
    background: no-repeat 60% 50%
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='7'><path d='m.7 3.7 2.5 2.5M8.5.7 3.2 6.2' fill='none' stroke='%2330483E'/></svg>") !important;
    background-color: #b0b0b0 !important;
}
.cky-switch input[type="checkbox"]:checked {
    background: no-repeat 60% 50%
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='7'><path d='m.7 3.7 2.5 2.5M8.5.7 3.2 6.2' fill='none' stroke='%2330483E'/></svg>") !important;
    background-color: #97f0a1 !important;
}
